import React from "react";
import "./Equipe.css";

const LivroAcademico = ({ integrante }) => {
  const { image, titulo } = integrante;

  return (
      <div className="card-img-container"><img className='img-produto' src={require(`../../assets/imgs/equipe/${image}`)} alt={titulo}></img></div>
  );
};

export default LivroAcademico;
