import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import "./Equipe.css";
import { CustomPrevArrow, CustomNextArrow } from "./CustomSliderArrow";
import Produto from "./EquipeCard.js";
import "./Slider.css";
import fakeItems from "./equipe.json";
function MaisVendidos() {
  const settings = {
    arrows: false,
    dots: false,
    pauseOnHover: false,
    infinite: true,
    speed: 5000,
    autoplaySpeed: 5000,
    cssEase: "linear",
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1150,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 780,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const main = "/editora-insigne/";
  return (
    <div className="home-equipe-wrapper">
      <div className="home-slider-container">
        <h2>nossa equipe</h2>
        <div className="maisvendidos-container">
          <Slider {...settings}>
            {fakeItems.map((product, index) => (
              <>
                <Produto key={product.id} integrante={product} />
              </>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
}

export default MaisVendidos;
