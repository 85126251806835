import React, { useState } from "react";
import "./Hero.css";
import Stats from "../stats/Stats";
import Insigne from '../../assets/imgs/insigne-marca (1).webp';


const CTA = () => {
  return (
    <div className="cta-wrapper">

      <div className="cta-layer">
        <div className="cta-container">
          <div className="home-text">
          <h4>Seja Insigne</h4>
            <h1>Conheça a Insigne Acadêmica</h1>
            <p>
              Nós ensinamos o passo a passo para desenvolver um trabalho
              científico de sucesso no âmbito jurídico e nas áreas afins a partir
              do Método CRP (Construção, Revisão e Publicação).
            </p>
            <p>
              Seja projeto, artigo, monografia, dissertação ou tese, a Insigne
              Acadêmica existe para ajudar a resolver sua vida no meio
              científico!
            </p>
            <p>
              Ao final, caso possua dimensão e conteúdo adequados, ainda podemos
              publicar o seu trabalho como livro físico e digital pela nossa
              Editora Insigne Acadêmica ou no Congresso Brasileiro de Direito
              Aplicado!
            </p>
            <a href="https://forms.gle/PMzGm86Xv7oTM1WE7" target="_blank" rel="noreferrer" alt="link para mentoria" className="main-btn alt">
              Garanta sua vaga agora mesmo! clique aqui!
            </a>
          </div>
          <div className="cta-img"><img src={Insigne} alt="logo"></img></div>
        </div>
      </div>
<div className="stats-wrapper">
  
        <Stats />
</div>

    </div>
  );
};

export default CTA;
